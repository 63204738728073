@tailwind base;
@tailwind components;
@tailwind utilities;
.custom-placeholder::placeholder {
    color:#5B45FF80;
    font-family: 'Gabarito', sans-serif; /* Custom font */
  }
  @media (min-width: 640px) {
    .custom-shadow {
      box-shadow: 0 4px 50px rgba(50, 22, 255, 0.4);
    }
  }

  progress[value]::-webkit-progress-value {
    background-color: #5B45FF; /* Your desired filled color */
    border-radius: 0.5rem; /* Match Tailwind's rounded class if needed */
}

progress[value]::-webkit-progress-bar {
    background-color: #EDEAFF; /* Your AIMOR_VIOLET_SECONDARY color for the background */
    border-radius: 0.5rem; /* Match Tailwind's rounded class if needed */
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
}
/* 
@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.slide-down {
  @apply animation-slideDown duration-500 ease-out;
} */
